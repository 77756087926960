<template>
	<!-- 咨询部专员的部门任务 -->
	<div class="bg-white">
		<el-tabs type="border-card">
			<el-tab-pane style="min-height: 70vh;">
				<span slot="label">
					<i class="el-icon-finished"></i>
					部门任务
				</span>
				
				<template>
					<div class="flex flex-wrap-wrap" v-if="GetTaskList.length" v-loading='loading'>
						<div class="padding border-grey border-radius  task-div"
							style="width: 24%;margin: 10px 10px 0 0;box-shadow: 0 0 5px #cdcdcd;"
							v-for="(item,index) of GetTaskList" :key="index" @click="staffTaskDetail(item.id)">
							<div style="position: absolute;top:20px;right: 10px;">
								<span>
									<svg class="icon BgIcon" aria-hidden="true">
										<use xlink:href="#icon-bumen"></use>
									</svg>
								</span>
							</div>
							<div style="height: 25px;width: 100%;">
								<span class="text-bold text-lg text-black"
									style="font-size: 18px;line-height: 25px">{{item.TaskTitle}}</span>
							</div>
							<div class="text ">{{item.TaskContent}}</div>
							<div class="padding-top-xs">
								<span>
									<i class="el-icon-time el-icon--left" />
									<span style="width: 70px;display: inline-block;">发布时间</span>
								</span>
								<span>
									<span>{{item.AddTime | dateTimeFilter}}</span>
								</span>
								<!-- <div style="float: right;font-weight: bold;">
									<span v-if="item.TaskStatus==1" style="color: red;">未执行</span>
									<span v-if="item.TaskStatus==2" style="color: #0077AA;">执行成功</span>
								</div> -->
							</div>
						</div>

					</div>
					<div v-else>
						<div style="text-align: center;">
							<div class="text-black" style="margin-top: 100px;">
								<i class="iconfont icon-zanwushuju" style="font-size: 64px;"></i>
								<div>暂无数据</div>
							</div>

						</div>
					</div>
				</template>


			</el-tab-pane>

		</el-tabs>
		<el-pagination background layout="total,prev, pager, next,sizes,jumper" highlight-current-row :total="total"
			:page-size="ListQuery.PageSize" :page-sizes="[15,20,50,100,200,500]" @current-change="pageIndexChange"
			@size-change="pageSizeChange" style="text-align: center;" />
	</div>
</template>

<script>
	import ButtonList from '../salesManagement/components/buttonList.vue';
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		components: {
			ButtonList,
		},
		data() {
			return {
				loading: true,
				editVisible: false,
				total:0,
				PlanStatusQuery: {
					CutomerUserId: 0,
					sale_PlanStatus: '1',
					PageIndex: 1,
					PageSize: 20,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				}, //销售计划的tab  即计划状态
				planIndex: 1,
				GetTaskList: [], //任务的数据
				ListQuery: {
					TaskStatus:1,
					All: false,
					PageIndex: 1,
					PageSize: 15,
					type:0,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				}

			};
		},
		created() {
			this.GetTaskLists()
		},
		mounted() {},
		methods: {
			pageSizeChange(val) {
				this.ListQuery.PageIndex = 1;
				this.ListQuery.pageSize = val;
				this.GetTaskLists();
			},
			pageIndexChange(val) {
				this.ListQuery.PageIndex = val;
				this.GetTaskLists();
			},
			GetTaskLists() {
				this.API.CustomerTaskGetTaskListByStatus(this.ListQuery).then(res => {
					this.loading = false
					this.GetTaskList = res.data.rows
					this.total = res.data.total
				})
			},
			staffTaskDetail(id){
				this.$Dialog.staffTaskDetails({taskId:id}).then(res=>{
					this.GetTaskLists();
				})
			}
		}
	};
</script>

<style scoped>
	.icon {
		width: 23px;
		height: 23px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.BgIcon {
		width: 100px;
		height: 100px;
		opacity: 0.3;
	}

	.task-div {
		cursor: pointer;
	}

	.task-div:hover {
		background-color: #efefef;
	}

	.text {
		height: 80px;
		font-size: 14px;
		text-indent: 26px;
		margin: 10px 0;
	/* 	overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2; */
	}
</style>
